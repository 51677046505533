<template>
	<div class="indexPage">
		<div class="viewContent">
			<!-- 头部导航 -->
			<div id="qifu-common-header_of_sdk">
				<header class="qifu-header-sdk-container">
					<div class="qifu-header-content">
						<div class="qifu-header-service-container">
							<div class="header-service-left">
								<a class="cloud-logo" href="" target="_blank">
									<!-- <img src="https://qifu-public.cdn.bcebos.com/portal/header/qifu-logo.png" target="_blank"> -->
									<img src="../assets/zwLogo.png" target="_blank">
								</a>
							</div>
							<div class="qifu-header-nav">
								<div class="qifu-header-nav-container">
									<div class="nav-item-container">
										<div class="nav-item-container-name">
											<a class="nav-item-text nav-item-text-cursor" target="_blank" href="">首页</a>
										</div>
									</div>
									<div class="nav-item-container">
										<div class="nav-item-container-name">
											<a class="nav-item-text nav-item-text-cursor" target="_blank"
												href="">注册服务</a>
										</div>
										
									</div>
									<div class="nav-item-container">
										<div class="nav-item-container-name">
											<a class="nav-item-text nav-item-text-cursor" target="_blank"
												href="">企业自媒体</a>
										</div>

									</div>
									<div class="nav-item-container">
										<div class="nav-item-container-name">
											<a class="nav-item-text nav-item-text-cursor" target="_blank"
												href="">备案确权</a>
										</div>

									</div>
									<div class="nav-item-container">
										<div class="nav-item-container-name">
											<a class="nav-item-text nav-item-text-cursor" target="_blank"
												href="">官媒标识数据库</a>
										</div>

									</div>
									<div class="nav-item-container">
										<div class="nav-item-container-name">
											<a class="nav-item-text nav-item-text-cursor" target="_blank"
												href="">数字对象</a>
										</div>

									</div>

									<div class="nav-item-container">
										<div class="nav-item-container-name">
											<a class="nav-item-text nav-item-text-cursor" target="_blank"
												href="">渠道合作</a>
										</div>
									</div>
									<div class="nav-active-line" style="width: 0px; left: 649px;"></div>
								</div>
							</div>
							<div class="header-service-right">
								<div class="user-container" v-if="token==''">
									<router-link class="login" id="loginContainer" :to="loginUrl">登录</router-link>
									<router-link class="register" id="registerContainer" :to="loginUrl">注册</router-link>
								</div>
								<div v-else class="base-info_qS_guBw">
									<router-link to="/my">
										<!-- <div class="avatar_l4eGGBq">1</div> -->
										<div class="avatar_l4eGGBq">
											<img :src="headImg" />
										</div>
										<div class="userName">
											{{userName}}
										</div>
									</router-link>
								</div>
							</div>
						</div>
						<div class="header-over-next-line"></div>
					</div>
				</header>
			</div>

			<!-- 页面内容 -->
			<div id="__next" data-reactroot="">
				<section class="aqzC4TR_">
					<h1 class="fLWV_gAf">
						<img src="../assets/gmLogo.png" />
						中文·官媒
					</h1>
					<div class="gT6nyuvE">官媒标识数据库，数字对象，相互识别，实现互通</div>
					<div class="__5GeaI43s">
						<input type="text" v-model="value" placeholder="请输入要查询的关键字" value="">
						<div class="X0RZtLhw ">
							<div>.官媒</div>
							<!-- <img src="https://bcd-public.cdn.bcebos.com/bcd_portal/20220211/arrow-down.svg" width="28" height="20" alt="箭头" title="箭头"> -->
						</div>
						<div class="P2A_K1AX" @click="serch">查询</div>
					</div>
				</section>
				
				<div class="__7ER_QVqG">
					<h2>为什么选择我们？</h2>
					<div class="tfQFxlvZ">为买卖双方提供丰富、安全、高效、便捷的官媒交易服务</div>
					<div class="yKbT9IHv">
						<div>
							<div class="S_ObAon2">
								<div class="BDU9KauV">品牌信誉</div>
								<div class="y2KWdEeY">官媒注册服务商</div>
								<div class="y2KWdEeY">为千万级用户提供服务</div>
							</div>
							<div class="kIO7I7O9"></div>
						</div>
						<div>
							<div class="EfksB8fH">
								<div class="BDU9KauV">官媒一键直达</div>
								<div class="y2KWdEeY">品牌传播与流量聚合</div>
								<div class="y2KWdEeY">实现新媒体环境数字化转型</div>
								<div class="y2KWdEeY">一键直抵官媒核心!</div>
							</div>
							<div class="JSo36z6S"></div>
						</div>
						<div>
							<div class="wIxDShAt">
								<div class="BDU9KauV">服务保障</div>
								<div class="y2KWdEeY">提供国内外百余种官媒后缀</div>
								<div class="y2KWdEeY">管理功能丰富安全，为客户保驾护航</div>
							</div>
							<div class="YH_qSi5O"></div>
						</div>
						<div>
							<div class="EdJioiH1">
								<div class="BDU9KauV">1V1大客户支持</div>
								<div class="y2KWdEeY">一对一大客户服务支持对接</div>
								<div class="y2KWdEeY">提供批量注册，高效能对接</div>
							</div>
							<div class="DwVlTwOs"></div>
						</div>
						<div class="P6NB8GVl">
							<video playsinline=""
								style="width:886px;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%)"
								autoplay="" loop="" muted="" src="../assets/vd2.mp4"></video>
							<!-- https://bcd-public.cdn.bcebos.com/bcd_portal/20220211/server-func.mp4 -->
						</div>
					</div>
				</div>

				<div class="cxd-Container"
					style="position:relative;display: block;background: url(&quot;https://bcd-public.bj.bcebos.com/beian/icp-banner.png&quot;) center center / cover no-repeat; overflow: visible; height: 408px; width: 100%; inset: 0px auto auto;">
					<div class="cxd-Container"
						style="width: 1180px; margin: 0px auto; position: absolute; top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%); z-index: 1;">
						<div class="cxd-Container-body">
							<div class="cxd-TplField bcd-banner-title">
								<span>官媒标识</span>
							</div>
							<div class="cxd-TplField bcd-banner-desc">
								<span>
									官媒标识，宛如 “物”
																之独特身份证，在这万物互联的智能自媒体广袤天地中，其涵盖范畴颇为广泛。既包含诸多实体对象，诸如企业名称（及其简称）、各类产品、项目工程、平台架构、设备器械，以及品牌（涵盖名称与
																LOGO），还有注册相关信息与服务期限等要素。当企业官媒标识成功完成备案确权流程后，便会被录入企业官媒标识专属数据库，而上述这些元素，皆被统称为
																“数字对象”。官媒标识的身份认证由一套标准化、统一化的体系负责发放与监管，正因如此，各个标识之间方能实现精准识别，进而达成高效互通。</br>
																官媒标识唯有具备明确身份，方可在互联互通的网络环境中顺畅地分发传播，从而实现彼此间的紧密连接，构建起相互信任的坚实桥梁，最终达成无缝的相互操作。
																这一标识充分彰显了企业专属且具权威性的宣传传播媒体渠道载体特质，其蕴含着企业内部浓郁的官方属性，秉持着诚信、可信以及真实无欺的显著特点，成为企业在信息洪流中独树一帜的鲜明旗帜。
								</span>
							</div>
							<!-- <div class="cxd-Container cxd-Container--default mt-10">
								<div class="cxd-Container-body">
									<button type="button"
										class="cxd-Button cxd-Button--primary cxd-Button--size-default bcd-banner-button">
										<span>立即购买</span>
									</button>
								</div>
							</div> -->
						</div>
					</div>
				</div>

				<!-- <div class="__7e8pXkFy">
					<div class="HTYJdT3x">
						<h3>官媒标识</h3>
						<div class="awNCaCTP">
							官媒标识，宛如 “物”
							之独特身份证，在这万物互联的智能自媒体广袤天地中，其涵盖范畴颇为广泛。既包含诸多实体对象，诸如企业名称（及其简称）、各类产品、项目工程、平台架构、设备器械，以及品牌（涵盖名称与
							LOGO），还有注册相关信息与服务期限等要素。当企业官媒标识成功完成备案确权流程后，便会被录入企业官媒标识专属数据库，而上述这些元素，皆被统称为
							“数字对象”。官媒标识的身份认证由一套标准化、统一化的体系负责发放与监管，正因如此，各个标识之间方能实现精准识别，进而达成高效互通。</br>
							官媒标识唯有具备明确身份，方可在互联互通的网络环境中顺畅地分发传播，从而实现彼此间的紧密连接，构建起相互信任的坚实桥梁，最终达成无缝的相互操作。
							这一标识充分彰显了企业专属且具权威性的宣传传播媒体渠道载体特质，其蕴含着企业内部浓郁的官方属性，秉持着诚信、可信以及真实无欺的显著特点，成为企业在信息洪流中独树一帜的鲜明旗帜。
						</div>

					</div>
				</div> -->
				
				<div class="tOdrs8RL">
					<h2>官媒服务</h2>
					<div class="nbxMagPI">
						<div class="serveOd">
							<div class="serTitle">官媒认证</div>
							<div class="line"></div>
							<div class="serCon">
								<div class="oMoney">
									原价<span>￥19800</span>/年
								</div>
								<div class="nMoney">
									现价<span>￥12800</span>/年
								</div>
							</div>
							<div class="toBuy" @click="formShow=true">立即购买</div>
						</div>
					</div>
				</div>

				<div class="GtB2x44_">
					<div class="HTYJdT3x">
						<h3>使用指南</h3>
						<ul class="CENliqSz">
							<li class="jMFeizYo">
								<div class="CVPeSD8M"><img
										src="https://bcd-public.cdn.bcebos.com/bcd_portal/20220211/huaban.svg"
										width="26" height="22" alt="购买官媒" title="购买官媒"><span>购买官媒</span></div>
								<div class="xYhtdRD9"><a target="_blank" href="" title="官媒选购指南">官媒选购指南</a><a
										target="_blank" href="" title="注册官媒">注册官媒</a><a target="_blank" href=""
										title="信息模板">信息模板</a><a target="_blank" href="" title="官媒实名认证">官媒实名认证</a></div>
							</li>
							<li class="jMFeizYo">
								<div class="CVPeSD8M"><img
										src="https://bcd-public.cdn.bcebos.com/bcd_portal/20220211/huaban.svg"
										width="26" height="22" alt="官媒管理" title="官媒管理"><span>官媒管理</span></div>
								<div class="xYhtdRD9"><a target="_blank" href="" title="官媒解析">官媒解析</a><a target="_blank"
										href="" title="官媒续费">官媒续费</a><a target="_blank" href="" title="官媒转入">官媒转入</a><a
										target="_blank" href="" title="变更官媒所有者">变更官媒所有者</a><a target="_blank" href=""
										title="官媒备案">官媒备案</a><a target="_blank" href="" title="官媒证书下载">官媒证书下载</a><a
										target="_blank" href="" title="修改DNS服务器">修改DNS服务器</a><a target="_blank" href=""
										title="官媒转至其他账号">官媒转至其他账号</a></div>
							</li>
							<li class="jMFeizYo">
								<div class="CVPeSD8M"><img
										src="https://bcd-public.cdn.bcebos.com/bcd_portal/20220211/huaban.svg"
										width="26" height="22" alt="官媒交易" title="官媒交易"><span>官媒交易</span></div>
								<div class="xYhtdRD9"><a target="_blank" href="" title="官媒委托购买">官媒委托购买</a><a
										target="_blank" href="" title="一口价（优选）">一口价（优选）</a><a target="_blank" href=""
										title="官媒优惠资源包">官媒优惠资源包</a></div>
							</li>
							<li class="jMFeizYo">
								<div class="CVPeSD8M"><img
										src="https://bcd-public.cdn.bcebos.com/bcd_portal/20220211/huaban.svg"
										width="26" height="22" alt="常见问题" title="常见问题"><span>常见问题</span></div>
								<div class="xYhtdRD9"><a target="_blank" href="" title="官媒注册及解析问题">官媒注册及解析问题</a><a
										target="_blank" href="" title="官媒流程及使用问题">官媒流程及使用问题</a><a target="_blank"
										href="" title="官媒问题">官媒问题</a><a target="_blank" href=""
										title="官媒转入问题">官媒转入问题</a></div>
							</li>
						</ul>
					</div>
				</div>
				<div class="xdEYFgfI">
					<div class="NjevmhwD">
						<h1 class="bTo3Q9Ue">心仪的官媒已被注册？委托专业官媒经纪人购买</h1>
						<div>
							<div>
								<form id="basic" class="ant-form ant-form-vertical __1_oPDu3W">
									<div class="ant-form-item">
										<div class="ant-row ant-form-item-row">
											<div class="ant-col ant-form-item-label"><label for="basic_domain" class=""
													title="购买官媒">购买官媒</label></div>
											<div class="ant-col ant-form-item-control">
												<div class="ant-form-item-control-input">
													<div class="ant-form-item-control-input-content"><input type="text"
															autocomplete="off" placeholder="请输入购买官媒" value=""
															id="basic_domain" class="ant-input ant-input-lg"></div>
												</div>
											</div>
										</div>
									</div>
									<div class="ant-form-item">
										<div class="ant-row ant-form-item-row">
											<div class="ant-col ant-form-item-label"><label for="basic_budget" class=""
													title="购买预算">购买预算</label></div>
											<div class="ant-col ant-form-item-control">
												<div class="ant-form-item-control-input">
													<div class="ant-form-item-control-input-content"><input type="text"
															autocomplete="off" placeholder="请输入购买预算" value=""
															id="basic_budget" class="ant-input ant-input-lg"></div>
												</div>
											</div>
										</div>
									</div>
									<div class="ant-form-item">
										<div class="ant-row ant-form-item-row">
											<div class="ant-col ant-form-item-label"><label for="basic_contactName"
													class="" title="联系人">联系人</label></div>
											<div class="ant-col ant-form-item-control">
												<div class="ant-form-item-control-input">
													<div class="ant-form-item-control-input-content"><input type="text"
															autocomplete="off" placeholder="请输入联系人" value=""
															id="basic_contactName" class="ant-input ant-input-lg"></div>
												</div>
											</div>
										</div>
									</div>
									<div class="ant-form-item">
										<div class="ant-row ant-form-item-row">
											<div class="ant-col ant-form-item-label"><label for="basic_contactPhone"
													class="" title="手机号码">手机号码</label></div>
											<div class="ant-col ant-form-item-control">
												<div class="ant-form-item-control-input">
													<div class="ant-form-item-control-input-content"><input type="text"
															autocomplete="off" placeholder="请输入手机号码" value=""
															id="basic_contactPhone" class="ant-input ant-input-lg">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="ant-form-item">
										<div class="ant-row ant-form-item-row">
											<div class="ant-col ant-form-item-label"><label for="basic_verifyCode"
													class="" title="验证码">验证码</label></div>
											<div class="ant-col ant-form-item-control">
												<div class="ant-form-item-control-input">
													<div class="ant-form-item-control-input-content">
														<div class="VDB5Rqxg" id="basic_verifyCode"><input type="text"
																autocomplete="off" placeholder="请输入验证码"
																class="ant-input ant-input-lg VDB5Rqxg" value="">
															<div class="ySf_LHta">获取验证码</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- <div class="XjHOjD38">
										<div class="__7h3Dcc__"><span>关注我们</span>
											<div class="m6jzY2tR"><img
													src="https://bcd-public.bj.bcebos.com/bcd_portal/20230309/cloud_qrcode.png"
													alt="二维码" title="二维码"></div>
										</div>
										<div class="UJrINRmm">
											<p>关注企业服务公众号，了解更多信息。</p>
											<p>还有不定期发放福利哦</p>
										</div>
									</div> -->
									<div class="ant-form-item">
										<div class="ant-row ant-form-item-row">
											<div class="ant-col ant-form-item-control">
												<div class="ant-form-item-control-input">
													<div class="ant-form-item-control-input-content"><button
															type="submit"
															class="ant-btn ant-btn-primary"><span>发布委托购买需求</span></button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

				<div class="U3NaSEQ_">
					<div class="d8lFppqW">
						<div class="ftBfYWa_">
							<div>
								<div class="O_Al1yEl">一个官媒，成就一个梦想</div>
								<!-- <a class="cN1LE_GF"	href=""	target="_blank" title="注册官媒">现在注册</a> -->
								<div class="cN1LE_GF" @click="formShow=true">现在注册</div>
							</div><video playsinline=""
								style="height:374px;position:absolute;left:50%;bottom:0;transform:translate(-50%, 0)"
								autoplay="" loop="" muted=""
								src="https://bcd-public.cdn.bcebos.com/bcd_portal/20220211/blue_bg.mp4"></video>
						</div>
					</div>
				</div>
			</div>

			<!-- 底部导航 -->
			<footer class="cloud-footer footer-pc-container">
				<div class="footer-pc">
					<div class="copy-right">
						<p style="text-align: center;">
							<a class="space" target="_blank" href="">© 2024 使用中文·官媒前必读</a>
							<!-- <span class="space">增值电信业务经营许可证：B1.B2-20100266</span> -->
							<a class="space" :href="icpUrl">{{icp}}</a>
							<!-- <a class="space" target="_blank">官媒注册服务机构许可：京D3-20210001</a> -->
							<!-- <span class="space">代理官媒注册服务机构：商务中国 新网数码 江苏邦宁 三五互联</span> -->
							<a class="space" target="_blank" :href="wabUrl" rel="noreferrer">{{wab}}</a>
							<a target="_blank" href="https://admin.iptzx.com/policy/privacy.html">隐私政策</a>
						</p>
					</div>
				</div>
			</footer>
			<!-- 提交表单 -->
			<div class="form_wrap" v-if="formShow">
				<div id="login">
					<div class="title">中文·官媒申请注册</div>
					<form id="register" class="ant-form ant-form-horizontal">
						<div class="ant-form-item">
							<div class="ant-row ant-form-item-row">
								<div class="ant-col ant-form-item-control">
									<div class="ant-form-item-control-input">
										<div class="ant-form-item-control-input-content">
											<input placeholder="请输入注册关键字" class="ant-input" type="text"
												v-model="subData.ym">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="ant-form-item">
							<div class="ant-row ant-form-item-row">
								<div class="ant-col ant-form-item-control">
									<div class="ant-form-item-control-input">
										<div class="ant-form-item-control-input-content">
											<input placeholder="请输入您的姓名" class="ant-input" type="text"
												v-model="subData.name">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="ant-form-item">
							<div class="ant-row ant-form-item-row">
								<div class="ant-col ant-form-item-control">
									<div class="ant-form-item-control-input">
										<div class="ant-form-item-control-input-content">
											<input placeholder="请输入您的联系电话" class="ant-input" type="text"
												v-model="subData.phone">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="ant-form-item">
							<div class="ant-row ant-form-item-row">
								<div class="ant-col ant-form-item-control">
									<div class="ant-form-item-control-input">
										<div class="ant-form-item-control-input-content">
											<!-- <input placeholder="注册官媒" class="ant-input" type="select" value=""> -->
											<select v-model="subData.sheng" @change="seleSheng">
												<option disabled selected>请选择省</option>
												<option v-for="(item,index) in sheng" :key="index" :value="item.id">
													{{item.name}}
												</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="ant-form-item">
							<div class="ant-row ant-form-item-row">
								<div class="ant-col ant-form-item-control">
									<div class="ant-form-item-control-input">
										<div class="ant-form-item-control-input-content">
											<!-- <input placeholder="注册官媒" class="ant-input" type="select" value=""> -->
											<select v-model="subData.shi" @change="seleShi">
												<option disabled selected>请选择市</option>
												<option v-for="(item,index) in shi" :key="index" :value="item.id">
													{{item.name}}
												</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="ant-form-item">
							<div class="ant-row ant-form-item-row">
								<div class="ant-col ant-form-item-control">
									<div class="ant-form-item-control-input">
										<div class="ant-form-item-control-input-content">
											<!-- <input placeholder="注册官媒" class="ant-input" type="select" value=""> -->
											<select v-model="subData.qu" placeholder="000">
												<option disabled selected>请选择区</option>
												<option v-for="(item,index) in qu" :key="index" :value="item.id">
													{{item.name}}
												</option>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="ant-form-item">
							<div class="ant-row ant-form-item-row">
								<div class="ant-col ant-form-item-control">
									<div class="ant-form-item-control-input">
										<div class="ant-form-item-control-input-content">
											<button type="button" class="ant-btn ant-btn-primary ant-btn-block"
												@click="subInfo">
												<span>提交</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<transition name="fade">
				<div class="tipBox" v-if="tipShow">
					这是提示的内容
				</div>
			</transition>
			<!-- 查询结果弹窗 -->
			<transition name="fade">
				<div class="resBox" v-show="resShow">
					<div class="resCon">{{resData}}</div>
					<div class="resBtn">
						<div @click="formShow=true,resShow=false">申请注册</div>
						<div @click="resShow=false">取消</div>
					</div>
				</div>
			</transition>
			<transition name="fade">
				<div class="mask" v-if="resShow || formShow" @click="resShow=false,formShow=false"></div>
			</transition>
		</div>
	</div>
</template>

<script>
	import {
		message
	} from 'ant-design-vue'
	export default {

		data() {
			return {
				formShow: false,
				value: '',
				tipShow: false,
				resShow: false,
				resData: '该官媒尚未注册',
				subData: {
					ym: '',
					name: '',
					phone: '',
					sheng: '',
					shi: '',
					qu: ''
				},
				sheng: [],
				shi: [],
				qu: [],
				token: '',
				headImg: '',
				userName: '',
				icp: '',
				icpUrl: '',
				wab: '',
				wabUrl: '',
				ysUrl: '',
				loginUrl:'login'
			}
		},
		onload() {

		},
		mounted() {
			this.getSs('', 1)
			this.icp = localStorage.getItem('icp')
			this.icpUrl = localStorage.getItem('icpUrl')
			this.wab = localStorage.getItem('wab')
			this.wabUrl = localStorage.getItem('wabUrl')
			if (localStorage.getItem('token')) {
				this.token = localStorage.getItem('token')
				this.userName = localStorage.getItem('nickname')
				this.headImg = localStorage.getItem('avatar')
			}
			this.isMobile()
		},
		methods: {
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				if(flag){
					this.loginUrl='mLogin'
				}else{
					this.loginUrl='login'
				}
				return flag;
			},
			async serch() {
				if (this.value.trim() == '') {
					message.info('请输入官媒查询')
					// console.log('请输入官媒查询')
					return
				}
				const res = await this.request({
					method: "post",
					url: "https://admin.iptzx.com/api/search/search",
					headers:{
						token:localStorage.getItem('token')
					},
					data: {
						key: this.value
					}
				})
				console.log(res)
				if (res.data.data == '') {
					this.resShow = true
				}
			},
			seleSheng(e) {
				this.getSs(this.subData.sheng, 2)
				this.subData.shi = ''
				this.subData.qu = ''
			},
			seleShi(e) {
				this.getSs(this.subData.shi, 3)
			},
			async getSs(id, type) {
				// console.log('type=' + type + ',' + 'id=' + id)
				const res = await this.request({
					method: "post",
					url: "https://admin.iptzx.com/api/index/district",
					data: {
						level: type,
						parent_id: id
					}
				})
				// console.log(res.data.data)
				if (type == 1) {
					this.sheng = res.data.data
				} else if (type == 2) {
					this.shi = res.data.data
				} else if (type == 3) {
					this.qu = res.data.data
				}
			},
			async subInfo() {
				const res = await this.request({
					method: "post",
					url: "https://admin.iptzx.com/api/index/form",
					headers:{
						token:localStorage.getItem('token')
					},
					data: {
						key: this.subData.ym,
						phone: this.subData.phone,
						name: this.subData.name,
						region: this.subData.sheng,
						city: this.subData.shi,
						area: this.subData.qu
					}
				})
				console.log(res)
				// if(res.data.code==0 && res.data.show==1){
				// 	alert(res.data.msg)
				// }
				if (res.data.code == 1) {
					// alert(res.data.msg)
					this.formShow = false
					message.success('提交成功')
				}
			}
		}
	}
</script>


<style scoped>
	@import url("../style/indexContent.css");
	@import url("../style/header.css");
	@import url("../style/footer.css");
	/* body {
	    margin: 0;
	    min-width: 1280px;
	} */

	.tipBox {
		width: 200px;
		height: 200px;
		border-radius: 10px;
		background-color: red;
		color: #fff;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-100px, -100px);
	}

	/* 定义淡入动画 */
	.fade-enter-active {
		transition: opacity 0.5s;
	}

	.fade-enter

	/* .fade-enter-active 和 .fade-enter-to 可以省略其中之一 */
		{
		opacity: 0;
	}

	/* 定义淡出动画 */
	.fade-leave-active {
		transition: opacity 0.5s;
		opacity: 0;
	}

	.userName {
		font-size: 14px !important;
		margin-right: 20px;
	}

	.resBox {
		width: 440px;
		height: 200px;
		border-radius: 10px;
		background-color: #fff;
		color: #000;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-220px, -100px);
		z-index: 999;
		/* transition: 1s; */
		/* transition: opacity 0.5s; */
	}

	.resCon {
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		height: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.mask {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 99;
	}

	.resBtn {
		height: 50px;
		border-top: 1px solid #eee;
		display: flex;
		box-sizing: border-box;
		cursor: pointer;

		div:nth-of-type(1) {
			border-right: 1px solid #eee;
		}

		div {
			flex: 1;
			text-align: center;
			line-height: 50px;
			font-size: 16px;
			box-sizing: border-box
		}
	}


	/* 定义淡出动画 */
	@keyframes fadeOut {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
		}
	}

	/* 定义淡入动画 */
	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	/* 应用淡出动画 */
	.fade-out {
		animation: fadeOut 3.5s ease-in-out forwards;
	}

	/* 应用淡入动画 */
	.fade-in {
		animation: fadeIn 0.5s ease-in-out forwards;
	}
</style>